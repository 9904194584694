<template>
  <b-dropdown
    size="md"
    variant="link"
    toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-xs btn-icon"
    no-caret
    right
    no-flip
    boundary="viewport"
  >
    <template v-slot:button-content>
      <i class="flaticon2-printer icon-lg"></i>
    </template>
    <b-dropdown-item-button v-if="isClient">
      <span role="button" class="d-flex" @click="editClientJob(id)">
        <span class="col-2"><i class="fas fa-edit"></i></span>
        <span class="col-10">Edit</span>
      </span>
    </b-dropdown-item-button>
    <b-dropdown-item-button v-if="isLogistics">
      <span role="button" class="d-flex" @click="edit(id)">
        <span class="col-2"><i class="fas fa-edit"></i></span>
        <span class="col-10">Edit</span>
      </span>
    </b-dropdown-item-button>
    <b-dropdown-item-button>
      <span role="button" class="d-flex" @click="waybill(id)">
        <span class="col-2"><i class="fas fa-receipt"></i></span>
        <span class="col-10">Print waybill</span>
      </span>
    </b-dropdown-item-button>
    <b-dropdown-item-button>
      <span role="button" class="d-flex" @click="ecommerce(id)">
        <span class="col-2"><i class="fas fa-qrcode"></i></span>
        <span class="col-10">Print QR label</span>
      </span>
    </b-dropdown-item-button>
    <b-dropdown-item-button>
      <span role="button" class="d-flex" @click="addToPrint(id)">
        <span class="col-2"><i class="flaticon2-printer"></i></span>
        <span class="col-10">Add to Print List</span>
      </span>
    </b-dropdown-item-button>
    <!-- <div class="navi navi-hover min-w-md-250px">
      <b-dropdown-text tag="div" class="navi-item">
        <span role="button" class="navi-link" @click="edit(id)" v-if="isLogistics">
          <span class="navi-icon"><i class="fas fa-edit"></i></span>
          <span class="navi-text">Edit</span>
        </span>
        <span role="button" class="navi-link" @click="waybill(id)">
          <span class="navi-icon"><i class="fas fa-receipt"></i></span>
          <span class="navi-text">Print waybill</span>
        </span>
        <span role="button" class="navi-link" @click="ecommerce(id)">
          <span class="navi-icon"><i class="fas fa-qrcode"></i></span>
          <span class="navi-text">Print QR label</span>
        </span>
        <span role="button" class="navi-link" @click="addToPrint(id)">
          <span class="navi-icon"><i class="flaticon2-printer"></i></span>
          <span class="navi-text">Add to Print List</span>
        </span>
      </b-dropdown-text>
    </div> -->
  </b-dropdown>
</template>

<script>
import parseISO from "date-fns/parseISO";
import add from "date-fns/add";
import isBefore from "date-fns/isBefore";

import { computed } from "@vue/composition-api";
import { getWaybill, getECommerceLabel } from "@/api/job.api";

export default {
  props: {
    id: Number,
    job: Object
  },
  setup(props, context) {
    const store = context.root.$store;
    const isLogistics = computed(() => store.getters.currentRole.includes("LOGISTICS"));
    const isClient = computed(() => {
      const isoDate = parseISO(props.job.created_at);
      const currentDateTimeAddOneHour = add(isoDate, { hours: 1 });
      const isOverOneHour = isBefore(new Date(), currentDateTimeAddOneHour);
      return store.getters.currentRole.includes("CLIENT") && isOverOneHour;
    });

    const editClientJob = id => {
      context.root.$router.push({ name: "UpdateClientJob", params: { id: id } }).catch(() => {
        context.root.$router.push({ name: "UpdateClientJob", params: { id: id } });
      });
    };

    const edit = id => {
      context.root.$router.push({ name: "UpdateJob", params: { id: id } }).catch(() => {
        context.root.$router.push({ name: "UpdateJob", params: { id: id } });
      });
    };

    const waybill = id => {
      getWaybill(id).then(response => {
        const newBlob = new Blob([response.data], { type: "application/pdf" });

        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob);
          return;
        }

        const data = window.URL.createObjectURL(newBlob);
        var link = document.createElement("a");
        link.href = data;
        link.download = `${id}.pdf`;
        link.click();
        setTimeout(function() {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(data);
        }, 100);
      });
    };

    const ecommerce = id => {
      getECommerceLabel(id).then(response => {
        const newBlob = new Blob([response.data], { type: "application/pdf" });

        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob);
          return;
        }

        const data = window.URL.createObjectURL(newBlob);
        var link = document.createElement("a");
        link.href = data;
        link.download = `${id}.pdf`;
        link.click();
        setTimeout(function() {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(data);
        }, 100);
      });
    };

    const addToPrint = id => {
      store.dispatch("addToPrintList", id);
    };

    return {
      isLogistics,
      isClient,

      editClientJob,
      edit,
      waybill,
      ecommerce,
      addToPrint
    };
  }
};
</script>
